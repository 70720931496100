import * as React from 'react';
import useStoryblok from '../hooks/useStoryblok.hook';
import renderBloks from '../utils/renderBloks';
import Layout from '../components/Layout/Layout.component';
import getMetaData from '../utils/getMetaData';
import { PageContext } from '../contexts/PageContext';
import { getPageStyle } from '../utils/getPageStyle';
import withMembershipContextWrapper from '../components/MembershipContextWrapper/MembershipContextWrapper';
import MembershipContextWrapper from '../components/MembershipContextWrapper/MembershipContextWrapper';
import { graphql, useStaticQuery } from 'gatsby';

function StoryBlokPreview({ location }) {
  const story = useStoryblok(null, location);

  if (!story) {
    return (
      <div className="py-8">
        <p className="italic text-center">Loading Preview...</p>
      </div>
    );
  }

  const pageStyle = getPageStyle(story?.content);
  const blocks = renderBloks(story?.content?.body || []);
  const seo = getMetaData(story?.content);

  const { gyms } = useStaticQuery(graphql`
    {
      gyms: allStoryblokEntry(filter: { field_component: { eq: "Gym" } }) {
        edges {
          node {
            name
            full_slug
            content
            uuid
          }
        }
      }
    }
  `);

  const getGym = (clubId) => {
    const gymData = gyms?.edges.find(({ node: { content } }) => {
      const { site_id } = JSON.parse(content);
      return site_id === clubId;
    });
    return gymData && JSON.parse(gymData.node.content);
  };

  // console.log(story?.content);

  return (
    <PageContext.Provider
      value={{
        isHeadingFlush: pageStyle === 'flush',
        uuid: story?.uuid,
        gym: getGym(story?.content?.club_id),
      }}
    >
      <MembershipContextWrapper uuid={story?.uuid}>
        <Layout metaData={seo}>{blocks}</Layout>
      </MembershipContextWrapper>
    </PageContext.Provider>
  );
}

export default StoryBlokPreview;
