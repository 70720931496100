import React, { ElementType } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { MembershipContext } from '../../contexts/MembershipContext';
import { orderMemberships } from '../../utils/orderMemberships';

interface withMembershipContextWrapperProps {
  uuid: string;
}

const MembershipContextWrapper: React.FC<withMembershipContextWrapperProps> = ({
  uuid,
  children,
}) => {
  const {
    gyms,
    memberships,
    gym_promotions,
    membership_promotions,
    membership_horizontal_layout,
    membership_vertical_layout,
    membership_comparison_layout,
  } = useStaticQuery(graphql`
    {
      gyms: allStoryblokEntry(filter: { field_component: { eq: "Gym" } }) {
        edges {
          node {
            name
            full_slug
            content
            uuid
          }
        }
      }
      memberships: allStoryblokEntry(
        filter: { field_component: { eq: "Membership" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
            uuid
          }
        }
      }
      gym_promotions: allStoryblokEntry(
        filter: { field_component: { eq: "GymPromotion" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
            uuid
          }
        }
      }
      membership_promotions: allStoryblokEntry(
        filter: { field_component: { eq: "MembershipPromotion" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
            uuid
          }
        }
      }
      membership_horizontal_layout: allStoryblokEntry(
        filter: { field_component: { eq: "MembershipHorizontalLayout" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
            uuid
          }
        }
      }
      membership_vertical_layout: allStoryblokEntry(
        filter: { field_component: { eq: "MembershipVerticalLayout" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
            uuid
          }
        }
      }
      membership_comparison_layout: allStoryblokEntry(
        filter: { field_component: { eq: "MembershipComparisonLayout" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
            uuid
          }
        }
      }
    }
  `);

  const getGymPromotionData = (type, uuid) => {
    const gymPromotionData = gym_promotions?.edges
      .filter(({ node: { content } }) => {
        const { applicable_gyms } = JSON.parse(content);
        return applicable_gyms.includes(uuid);
      })
      .map(({ node: { content } }) => JSON.parse(content));
    if (gymPromotionData)
      return gymPromotionData.find((promotion) => promotion.type === type);
  };

  const getMembershipPromotionData = (type, uuid) => {
    const membershipPromotionData = membership_promotions?.edges
      .filter(({ node: { content } }) => {
        const { applicable_memberships } = JSON.parse(content);
        return applicable_memberships.includes(uuid);
      })
      .map(({ node: { content } }) => JSON.parse(content));
    if (membershipPromotionData)
      return membershipPromotionData.find(
        (promotion) => promotion.type === type
      );
  };

  const getMemberships = (uuid) => {
    const membershipsData = memberships?.edges
      .filter(({ node: { content } }) => {
        const { applicable_gyms } = JSON.parse(content);
        return applicable_gyms.includes(uuid);
      })
      .map(({ node: { content, uuid } }) => {
        return {
          ...JSON.parse(content),
          uuid: uuid,
          membership_up_sell: JSON.parse(content).membership_up_sell?.content,
        };
      });

    const allMemberships = membershipsData.map((membership) => ({
      ...membership,
      ...{
        promotion:
          getGymPromotionData('promotion', uuid) ||
          getMembershipPromotionData('promotion', membership.uuid),
        joiningFeePromotion:
          getGymPromotionData('joining_fee', uuid) ||
          getMembershipPromotionData('joining_fee', membership.uuid),
      },
    }));

    return {
      memberships: orderMemberships(
        allMemberships.filter((membership) => !membership.concession_membership)
      ),
      concessionMemberships: orderMemberships(
        allMemberships.filter((membership) => membership.concession_membership)
      ),
    };
  };

  const getMembershipDetails = (uuid) => {
    const membershipHorizontalLayout = JSON.parse(
      membership_horizontal_layout?.edges[0]?.node?.content
    );
    const membershipVerticalLayout = JSON.parse(
      membership_vertical_layout?.edges[0]?.node?.content
    );
    const membershipComparisonLayout = JSON.parse(
      membership_comparison_layout?.edges[0]?.node?.content
    );

    if (membershipHorizontalLayout.applicable_gyms.includes(uuid)) {
      return membershipHorizontalLayout;
    }
    if (membershipVerticalLayout.applicable_gyms.includes(uuid)) {
      return membershipVerticalLayout;
    }
    if (membershipComparisonLayout.applicable_gyms.includes(uuid)) {
      return membershipComparisonLayout;
    }
  };

  return (
    <MembershipContext.Provider
      value={{
        ...getMemberships(uuid),
        membershipDetails: getMembershipDetails(uuid),
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
};

export default MembershipContextWrapper;
